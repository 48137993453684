<template>
	<article
		class=" col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx "
	>
		<h1 class="h1">Inloggen</h1>

		<form class="p-3" @submit.prevent="inlog">
			<label for="email">Email: </label>
			<input
				type="email"
				placeholder="email"
				name="email"
				id="email"
				v-model="email"
			/>
			<label for="password">Password: </label>
			<input
				type="password"
				placeholder="password"
				name="password"
				id="password"
				v-model="password"
			/>

			<button class="btn mt-2" type="submit">
				Inlog
			</button>

			
		</form>
	</article>
</template>

<script>
	import { saveUser } from '@/data/db'

	export default {
		name: 'Login',

		data() {
			return {
				email: '',
				password: '',
				error: '',
			}
		},

		methods: {
			async inlog() {
				try {
					await saveUser.signInWithEmailAndPassword(
						this.email,
						this.password
					)

					this.$router.replace({ name: 'Home' })

				} catch (error) {
					console.log(error)
				}


				
			},
		},
	}
</script>

<style scoped lang="scss">
	form {
		width: 100%;

		label {
			display: block;
		}
	}
</style>
