<template>
	<article class="">
		<Login />
	</article>
</template>

<script>
	import Login from '@/components/Login'
	export default {
		components: {
			Login,
		},
	}
</script>

<style></style>
